import React from 'react';
import {StaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import "../styles/banner.less";

const Banner = (props) => (
    <StaticQuery
        query={graphql`
            query {
                images: allFile {
                    edges {
                        node {
                            relativePath
                            name
                            childImageSharp {
                                fluid(maxWidth: 2000) {
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                }
            }
        `}

        render={(data) => {
            const image = data.images.edges.find(n => {
                return n.node.relativePath.includes(props.filename);
            });
            if (!image) { return null; }
        
            return (
                <BackgroundImage
                fluid={image.node.childImageSharp.fluid}
                className={props.className}
                loading="eager"
                fadeIn={false}
                >
                    <div className="overlay"></div>
                    <div className = "container">
                        <div className="row align-items-center justify-content-start">
                            <div className="col-sm-12 col-md-6">
                                <h1>{props.title}</h1>
                                <p>{props.subtitle}</p>
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
            );
        }}
    />
)

export default Banner;